import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02bcc518"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "kt_header",
  class: "header",
  "data-kt-sticky": "true",
  "data-kt-sticky-name": "header",
  "data-kt-sticky-offset": "{default: '200px', lg: '300px'}",
  style: {"animation-duration":"0.3s"}
}
const _hoisted_2 = {
  id: "header-title",
  class: "mt-10",
  style: {"font-weight":"bold","font-size":"20px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTTabletAndMobileLogo = _resolveComponent("KTTabletAndMobileLogo")!
  const _component_KTTopbar = _resolveComponent("KTTopbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([{
        'container-fluid': _ctx.headerWidthFluid,
        container: !_ctx.headerWidthFluid,
      }, "d-flex justify-content-between"]),
      id: "kt_header_container"
    }, [
      _createVNode(_component_KTTabletAndMobileLogo),
      _createElementVNode("div", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.headerTitle) + " ", 1),
        _createElementVNode("i", {
          class: _normalizeClass(["ms-2", _ctx.icon]),
          style: {"font-size":"20px"}
        }, null, 2)
      ]),
      _createVNode(_component_KTTopbar)
    ], 2)
  ]))
}